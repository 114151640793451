import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`6th September 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can specify the maximum number of event loops to handle connections. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1886"
          }}>{`#1886`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactoryBuilder builder = ...;
builder.maxNumEventLoopsPerEndpoint(5); // Assign 5 event loops for endpoints. 1 is used by default.
builder.maxNumEventLoopsPerHttp1Endpoint(10); // Assign 10 event loops for HTTP/1.1 endpoints. 1 is used by default.
// You can set a customized function.
builder.maxNumEventLoopsFunction(endpoint -> {
    if (endpoint.equals(Endpoint.of("foo.com"))) {
        return 5;
    }
    if (endpoint.host().contains("bar.com")) {
        return Integer.MAX_VALUE; // The value will be clamped at the number of event loops.
    }
    return -1; // Should return 0 or a negative value to use the default value.
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now implement your own `}<inlineCode parentName="p">{`EventLoopScheduler`}</inlineCode>{` which schedules `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{`s those handle connections. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1886"
          }}>{`#1886`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now see Armeria modules and their versions in DocService. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/685"
          }}>{`#685`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1744"
          }}>{`#1744`}</a>{`
`}<img parentName="p" {...{
            "src": "https://user-images.githubusercontent.com/17493311/63525374-9038e900-c538-11e9-8f6e-819497cd5b0c.png",
            "alt": "version"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily combine `}<inlineCode parentName="p">{`EndpointGroup`}</inlineCode>{`s and `}<inlineCode parentName="p">{`Endpoint`}</inlineCode>{`s. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1897"
          }}>{`#1897`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1939"
          }}>{`#1939`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Endpoint foo = ...;
Endpoint bar = ...;
DynamicEndpointGroup group1 = ...;
DynamicEndpointGroup group2 = ...;
EndpointGroup composite = EndpointGroup.of(foo, bar, group1, group2);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can use the `}<a parentName="p" {...{
            "href": "https://pdfs.semanticscholar.org/e575/e12ef56bcd889ce09516dd702ec1422816b6.pdf"
          }}>{`Fibonacci backoff`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1968"
          }}>{`#1968`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1979"
          }}>{`#1979`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Backoff backoff = Backoff.fibonacci(100 /* initial delay millis */,
                                    10000 /* max delay millis */);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now reuse the configuration of existing Armeria client when creating an Armeria Retrofit client and `}<inlineCode parentName="p">{`HealthCheckedEndpointGroup`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2019"
          }}>{`#2019`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2020"
          }}>{`#2020`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpClient myClient = ...;
ArmeriaRetrofitBuilder builder = new ArmeriaRetrofitBuilder();
// Use the same settings and decorators with \`myClient\` when sending requests.
builder.clientOptions(myClient.options());

HealthCheckedEndpointGroupBuilder builder2 = new HealthCheckedEndpointGroupBuilder();
builder2.clientOptions(myClient.options());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can unwrap a `}<inlineCode parentName="p">{`Client`}</inlineCode>{` and bring the decorating client instance via `}<inlineCode parentName="p">{`Unwrappable.as()`}</inlineCode>{` or `}<inlineCode parentName="p">{`ClientFactory.unwrap()`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1883"
          }}>{`#1883`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2018"
          }}>{`#2018`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2029"
          }}>{`#2029`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpClient client = new HttpClientBuilder()
    .decorator(LoggingClient.newDecorator())
    .build();
LoggingClient unwrapped = client.as(LoggingClient.class).get();
LoggingClient unwrapped2 = clientFactory.unwrap(client, LoggingClient.class).get();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily retrieve the port number of the running server. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1956"
          }}>{`#1956`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1967"
          }}>{`#1967`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
Server server = sb.http(0) // Use an ephemeral port.
                  .build();
...
int port = server.activeLocalPort();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The delay calculation performance in `}<inlineCode parentName="li">{`ExponentialBackoff`}</inlineCode>{` is improved. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1983"
        }}>{`#1983`}</a></li>
      <li parentName="ul">{`You can now run all `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`Armeria examples`}</a>{` using `}<inlineCode parentName="li">{`gradle run`}</inlineCode>{` or `}<inlineCode parentName="li">{`gradle bootRun`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1988"
        }}>{`#1988`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see a warning message when the connection is closed before the current session protocol is detected. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2043"
        }}>{`#2043`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2048"
        }}>{`#2048`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ByteBufHttpData`}</inlineCode>{` does not leak anymore when an exception is raised by a client-side decorator. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2034"
        }}>{`#2034`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when the remote server requires a protocol downgrade. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2010"
        }}>{`#2010`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2021"
        }}>{`#2021`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Only one HTTP/2 connection is made per endpoint by default. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1886"
        }}>{`#1886`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Previously, an Armeria client made as many connections as the number of event loops for each endpoint. Now, it creates only one connection per endpoint so one `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{` handles all requests. Because of that, you may see performance degradation. If you want it to work as before, specify `}<inlineCode parentName="li">{`maxNumEventLoopsPerEndpoint`}</inlineCode>{` with the number of event loops in `}<inlineCode parentName="li">{`ClientFactoryBuilder`}</inlineCode>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <p>{`We now use `}<a parentName="p" {...{
        "href": "https://www.baeldung.com/spring-maven-bom"
      }}>{`Maven Boms(Bill of Materials)`}</a>{` for Jackson, Brave and Netty`}</p>
    <ul>
      <li parentName="ul">{`Brave 5.6.10 -> 5.7.0`}</li>
      <li parentName="ul">{`Guava 28.0-jre -> 28.1-jre`}</li>
      <li parentName="ul">{`Jackson 2.9.9 -> 2.9.9.20190807`}</li>
      <li parentName="ul">{`net.shibboleth.utilities 7.3.0 -> 7.5.0`}</li>
      <li parentName="ul">{`OpenSAML 3.3.0 -> 3.4.3`}</li>
      <li parentName="ul">{`Reactivestreams 1.0.2 -> 1.0.3`}</li>
      <li parentName="ul">{`Reactor 3.2.11.RELEASE -> 3.2.12.RELEASE`}</li>
      <li parentName="ul">{`RxJava2 2.2.11 -> 2.2.12`}</li>
      <li parentName="ul">{`Spring boot 2.1.7.RELEASE -> 2.1.8.RELEASE`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'delegacy', 'hexoul', 'ho9science', 'ikhoon', 'imasahiro', 'jyblue', 'KangWooJin', 'kojilin', 'mauhiz', 'minwoox', 'thinkgruen', 'trustin', 'Untaek', 'zacscoding']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      